import axios from '../utils/request';

// 服务机构列表
export function service_agency_list(params) {
  console.log(params);
  return axios({
    url: '/service_agency_list',
    params,
  });
}

// 服务机构详情
export function service_agency_info(params) {
  console.log(params);
  return axios({
    url: '/service_agency_info',
    params,
  });
}
// 律师列表
export function lawyer_list(params) {
  console.log(params);
  return axios({
    url: '/lawyer_list',
    params,
  });
}
// 律师详情
export function lawyer_info(params) {
  console.log(params);
  return axios({
    url: '/lawyer_info',
    params,
  });
}
// 产品列表
export function product_list(params) {
  console.log(params);
  return axios({
    url: '/product_list',
    params,
  });
}
//产品详情
export function product_info(params) {
  console.log(params);
  return axios({
    url: '/product_info',
    params,
  });
}
// 典型案例分析或法律知识列表
export function cases_list(params) {
  console.log(params);
  return axios({
    url: '/cases_list',
    params,
  });
}
