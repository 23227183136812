<template>
  <div style="padding-top: 126px">
    <app-header />
    <div class="LegalService">
      <div class="w">
        <div class="current_position">
          <div class="current_position_text">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
              v-for="(item, i) in $route.meta.breadcrumb"
              :to="item.path ? { path: item.path } : ''"
              :key="i"
              >{{ item.name }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <!-- 机构 -->
        <div class="service">
          <div class="left">
            <!--服务机构  -->
            <div class="serve">
              <div class="organization">
                <div class="img">
                  <img src="../../assets/image/组织.png" alt="" />
                </div>
                服务机构
              </div>
              <div class="more" @click="$router.push('/organizationDetails')">
                更多
                <div class="img">
                  <img src="../../assets/image/19.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 内容 -->
            <div
              class="site"
              v-for="item in result"
              :key="item.id"
              @click="$router.push('/particulars/' + item.id)"
            >
              <div class="logo">
                <img class="pic" :src="item.agency_img" alt="" />
              </div>
              <div class="message">
                <div class="th">{{ item.agency_name }}</div>
                <div class="tb" style="margin-bottom: 10px">
                  地址：{{ item.address }}
                </div>
                <div class="tb">电话:{{ item.mobile }}</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="personage">
              <div class="organization">
                <div class="img">
                  <img src="../../assets/image/组织.png" alt="" />
                </div>
                律师
              </div>
              <div class="more" @click="$router.push('/lawyers')">
                更多
                <div class="img">
                  <img src="../../assets/image/19.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 线 -->
            <div class="wire"></div>
            <!-- 内容 -->
            <div class="Personal">
              <div
                class="left"
                v-for="item in lawyerList"
                :key="item.id"
                @click="$router.push('/lawyersDetails/' + item.id)"
              >
                <div class="img">
                  <img class="pic" :src="item.lawyer_img" alt="" />
                </div>
                <div class="introduce">
                  <div class="th">{{ item.full_name }}</div>
                  <div class="tb">{{ item.work_unit }}</div>
                  <div class="tb tb2">
                    执业证号:
                    <div class="aaa">{{ item.license_no }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 产品-->
        <div class="product">
          <div class="product_head">
            <div class="organization">
              <div class="img">
                <img src="../../assets/image/产品.png" alt="" />
              </div>
              相关产品
            </div>
            <div class="more" @click="$router.push(`/products`)">
              更多
              <div class="img">
                <img src="../../assets/image/19.png" alt="" />
              </div>
            </div>
          </div>
          <div class="product_box">
            <!-- 产品内容 -->
            <div
              class="freight"
              v-for="item in productList"
              :key="item.id"
              @click="$router.push(`/productsDetails/${item.id}`)"
            >
              <div class="freight_head">
                <img :src="item.product_img" alt="" />
              </div>
              <div class="freight_text">
                <div class="text">
                  {{ item.product_name }}
                </div>
                <div class="price">{{ item.price }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="document_box">
        <div class="document w">
          <div class="left">
            <div class="th">
              <div class="organization">
                <div class="img">
                  <img src="../../assets/image/政策法规_linear.png" alt="" />
                </div>
                典型案例分析
              </div>
              <div class="more" @click="$router.push('/typicalDaseDetails')">
                更多
                <div class="img">
                  <img src="../../assets/image/19.png" alt="" />
                </div>
              </div>
            </div>
            <div
              class="tb"
              v-for="item in casesList"
              :key="item.id"
              @click="$router.push('/typicalDaseDetails/' + item.id)"
            >
              <div class="tb_left"><span></span>{{ item.title }}</div>
              <div class="tb_right">{{ item.create_time | formData }}</div>
            </div>
          </div>
          <div class="right left">
            <div class="th">
              <div class="organization">
                <div class="img">
                  <img src="../../assets/image/政策法规_linear.png" alt="" />
                </div>
                法律知识
              </div>
              <div class="more" @click="$router.push('/lawDetails')">
                更多
                <div class="img">
                  <img src="../../assets/image/19.png" alt="" />
                </div>
              </div>
            </div>
            <div
              class="tb"
              v-for="item in casesList2"
              :key="item.id"
              @click="$router.push('/lawDetails/' + item.id)"
            >
              <div class="tb_left"><span></span>{{ item.title }}</div>
              <div class="tb_right">{{ item.create_time | formData }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer/footer.vue";
import Header from "../../components/Header/header.vue";
import {
  service_agency_list,
  lawyer_list,
  product_list,
  cases_list,
} from "../../api/policies.js";
export default {
  data() {
    return {
      result: [], //服务机构列表
      lawyerList: [], //律师列表
      productList: [], //产品列表
      casesList: [], //典型案例分析
      casesList2: [], //法律知识列表
    };
  },
  filters: {
    formData(time) {
      var date = new Date(time);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      let day = date.getDate();
      day = day >= 10 ? day : "0" + day;
      return `${year}年${month}月${day}日`;
    },
  },
  created() {
    console.log(this.$route.meta.breadcrumb);
    this.service_agency_list(); //服务机构列表
    this.lawyer_list(); //律师列表
    this.product_list(); //产品列表
    this.cases_list(); //典型案例分析
    this.cases_list2(); //法律知识列表
  },
  methods: {
    FNFN() {
      // this.$route.push('/lawyers/');
      // console.log(this.$route.path);
    },
    getParams(obj) {
      return Object.assign(
        {
          page: "1",
          limit: "1",
          keyword: "",
        },
        obj
      );
    },
    async service_agency_list() {
      const params = this.getParams({ limit: "3" });
      const { result } = await service_agency_list(params);
      this.result = result;
    },
    async lawyer_list() {
      const params = this.getParams({ limit: "4" });
      const res = await lawyer_list(params);
      this.lawyerList = res.result;
    },
    async product_list() {
      // const parameter = {
      //   page: '1',
      //   limit: '15',
      //   keyword: '',
      // };
      const params = this.getParams({ limit: "15" });
      const { result } = await product_list(params);
      this.productList = result;
    },
    async cases_list() {
      const params = this.getParams({ page: 1, limit: 8, type: "1" });
      const { result } = await cases_list(params);
      this.casesList = result;
    },
    async cases_list2() {
      // const parameter = {
      //   page: 1,
      //   limit: 15,
      //   type: '2',
      //   keyword: '',
      // };
      const params = this.getParams({ page: 1, limit: 8, type: "2" });
      const { result } = await cases_list(params);
      this.casesList2 = result;
    },
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
};
</script>

<style scoped>
.aaa {
  height: 36px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.LegalService {
  /* font-size: 100px; */
  margin: 0 auto;
  padding-top: 20px;
  background-color: #fff;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.w {
  width: 1520px;
  margin: 0 auto;
}

.service {
  display: flex;
  margin-top: 29px;
  margin-bottom: 10px;
}
.left {
  width: 730px;
  margin-bottom: 20px;
}
.left .serve {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.organization {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
}
.organization .img {
  margin-right: 20px;
  width: 18px;
  height: 18px;
  /* border: 1px dashed #666; */
}
.organization .img img {
  width: 100%;
  height: 100%;
}
.more {
  display: flex;
  align-items: center;
  color: #808080;
}
.more .img {
  margin-left: 11px;
  width: 16px;
  height: 16px;
  /* border: 1px dashed #666; */
}
.more img {
  width: 100%;
  height: 100%;
}
.left .site {
  display: flex;
  width: 100%;
  height: 166px;
  /* background-color: #6cf; */
  padding: 20px 0 0;
  border-top: 1px solid #e8e8e8;
}
.left .site .logo {
  object-fit: cover;
  width: 302px;
  height: 106px;
  overflow: hidden;
  border: 1px solid #e8e8e8;
}
.left .site .logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.left .site .message {
  width: 408px;
  flex: 1;
  margin-left: 20px;
  word-break: break-all;
  /* display: flex; */
}
.left .site .message .th {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  height: 33.33%;
}
.left .site .message .tb {
  max-height: 28%;
  font-size: 16px;
  color: #595959;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.right {
  margin-left: 60px;
  flex: 1;
}
.right .Personal {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.right .Personal .left {
  display: flex;
  width: 355px;
  height: 220px;

  border: 1px solid #e8e8e8;
  padding: 20px;
}
.right .Personal .left .img {
  margin-right: 20px;
  width: 150px;
  height: 180px;
  object-fit: cover;
  overflow: hidden;
}
.Personal .left .img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.right .introduce {
  width: 150px;
  word-break: break-all;
}
.right .introduce .th {
  height: 33.33%;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.right .introduce .tb {
  height: 33.33%;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.right .introduce .tb2 {
  color: #595959;
}
.right .Personal .marl_20 {
  margin-left: 20px;
}

/*  */
.product_box {
  display: flex;
  margin-bottom: 30px;
  padding: 0 5px;
  width: 100%;
  overflow: hidden;
}
.right .personage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right .personage .organization {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
}
.right .personage .organization .img {
  margin-right: 20px;
  width: 18px;
  height: 18px;
  /* border: 1px dashed #666; */
}
.right .personage .organization .img img {
  width: 100%;
  height: 100%;
}
.right .more {
  display: flex;
  align-items: center;
  color: #808080;
}
.right .more .img {
  margin-left: 11px;
  width: 16px;
  height: 16px;
}
.right .more img {
  width: 100%;
  height: 100%;
}
.right .wire {
  margin: 20px 0;
  border-bottom: 1px solid #e8e8e8;
}

.product_head {
  width: 100%;
  display: flex;
  align-content: start;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.product .freight {
  margin-top: 30px;
  margin-right: 36px;
  width: 272px;
  height: 263px;
  box-sizing: content-box;
  border: 1px solid #f2f2f2;
  /* box-shadow: -2px 0px 8px 2px rgba(0, 132, 255, 0.15); */
}
.product .freight:hover {
  box-shadow: -2px 0px 8px 2px rgba(0, 132, 255, 0.15);
}
.product .freight .freight_head {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 272px;
  height: 170px;
  border-bottom: 1px solid #e8e8e8;
  object-fit: cover;
  overflow: hidden;
}
.product .freight_text {
  padding: 20px;
}
.product .freight .freight_head img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.product .freight_text .text {
  width: 100%;
  height: 18px;
  font-weight: 700;
  color: #333333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-spacing: normal;
}
.product .freight_text .price {
  font-weight: 700;
  font-size: 18px;
  margin-top: 15px;
  color: #ffa71d;
}
.document {
  display: flex;
  width: 1520px;
  height: 460px;
}
.document .left {
  background-color: #fff;
}

.LegalService .document .left .tb .tb_left:hover {
  color: #0d8eff !important ;
}

.document .left .th {
  display: flex;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 30px;
}
.document .left .tb {
  display: flex;
  justify-content: space-between;
  padding: 24px 30px 0 50px;
}
.document .left .tb .tb_left {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  position: relative;
}
.document .left .tb .tb_left::before {
  content: "";
  position: absolute;
  top: 7px;
  width: 6px;
  height: 6px;
  background: #1684fc;
  border-radius: 100%;
  left: -20px;
}
.document .left .tb .tb_left span {
  width: 10px;
  height: 10px;
  background-color: #1684fc;
  border-radius: 50%;
}
.document .left .tb .tb_right {
  width: 156px;
  font-size: 14px;
  font-weight: 400;
  color: #808080;
}
.document .right {
  flex: 1;
}
.document_box {
  padding-top: 30px;
  padding-bottom: 60px;
  background-color: #fafafa;
}
.current_position {
  display: flex;
  align-items: center;
}
.current_position_text {
  font-size: 14px;
  color: #838383;
}
.pic {
  transition: all 1s;
}
.pic:hover {
  transform: scale(1.5);
  /* transition: all 1s; */
}
.index_radius {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0d8eff;
}
</style>
